import React from "react";
import OwlCarousel from "react-owl-carousel";
import "./Style/homepage.css";
import img1 from './carousel-1.jpg';
import img2 from './carousel-2.jpg';
// import './Style/navbar.css'
function Home() {
  const categories = [
    { name: "ReactJS", imgSrc: "img/react.png" },
    { name: "NodeJS", imgSrc: "img/node.webp" },
    { name: "ExpressJS", imgSrc: "img/express-js.png" },
    { name: "MongoDB", imgSrc: "img/mongo.png" },
    { name: "Django", imgSrc: "img/django.png" },
    { name: "HTML", imgSrc: "img/html.png" },
    { name: "CSS", imgSrc: "img/css.svg" },
    { name: "Javascript", imgSrc: "img/js.png" },
  
    { name: "Python", imgSrc: "img/cat3.png" },
    { name: "Java", imgSrc: "img/cat4.png" },
    { name: "Web Design", imgSrc: "img/cat5.png" },
    
    { name: "MySQL", imgSrc: "img/cat7.png" },
    
  ];

  const services = [
    {
      icon: "img/icon1.png",
      title: "Learn anything",
      description:
        "Explore any interest or trending topic, take prerequisites, and advance your skills",
    },
    {
      icon: "img/icon2.png",
      title: "Save money",
      description:
        "Spend less money on your learning if you plan to take multiple courses this year",
    },
    {
      icon: "img/icon3.png",
      title: "Flexible learning",
      description:
        "Learn at your own pace, move between multiple courses, or switch to a different course",
    },
    {
      icon: "img/icon4.png",
      title: "Unlimited certificates",
      description:
        "Earn a certificate for every learning program that you complete at no additional cost",
    },
  ];
  return (
    <div>
      <div className="container-fluid p-0 mb-4">
        <OwlCarousel
          className="owl-carousel header-carousel position-relative"
          items={1}
          loop
          autoplay
          autoplayTimeout={5000}
          animateOut="fadeOut"
          animateIn="fadeIn"
          nav={false}
          dots={true}
        >
          {/* Carousel Item 1 */}
          <div className="owl-carousel-item position-relative">
            <img
              className="img-fluid"
              src={img1}
              alt="Carousel 1"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5
                      className="text-uppercase mb-3 animated slideInDown"
                      style={{ color: "#fb873f" }}
                    >
                      Best E-learning platform
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      Learn Job ready skills from free online courses with
                      certificates
                    </h1>
                    <p className="text-white mb-4 pb-2">
                      Explore a wide range of courses designed to enhance your expertise in technology, business, arts, and more. Start learning today!

                    </p>
                    <a
                      href="
                      /aboutus"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href="/contact"
                      className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                     Contact Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Item 2 */}
          <div className="owl-carousel-item position-relative">
            <img
              className="img-fluid"
              src={img2}
              alt="Carousel 2"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5
                      className="text-uppercase mb-3 animated slideInDown"
                      style={{ color: "#fb873f" }}
                    >
                      Welcome to NextGen Internship & Research canter(NIRC)
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      Interactive Learning Experience
                    </h1>
                    <p className="text-white mb-4 pb-2">
                      Engage with interactive lessons, quizzes, and projects.
                      Experience hands-on learning that keeps you motivated and
                      inspired.
                    </p>
                    <a
                      href="/aboutus"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href="/contact"
                      className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Contact Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <div className="invest-section container-xxl py-5">
      <div className="container">
        {/* Header section */}
        <div className="row g-2 text-center">
          <div className="col-12 wow fadeInUp" data-wow-delay="0.3s">
            <h1 className="title" style={{fontSize:'34px'}}>
              Invest in your professional goals with Secret Coder
            </h1>
            <p className="mb-5 description" style={{color:'black', fontSize:'24px'}}>
              Get unlimited access to over 90% of courses, Projects,
              Specializations, and Professional Certificates on Coursera,
              taught by top instructors.
            </p>
          </div>
        </div>

        {/* Service items */}
        <div className="row g-4">
          {services.map((service, index) => (
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay={`${0.1 + index * 0.2}s`}
              key={index}
            >
              <div className="service-item">
                <div className="p-4">
                  <img
                    src={service.icon}
                    alt={service.title}
                    width="60px"
                    className="mb-4"
                  />
                  <h5 className="mb-3" style={{fontSize:'24px'}}>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            {/* Image Section */}
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/about.jpg"
                  alt="About Internshipwala"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>

            {/* Text Content Section */}
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Welcome to Internshipwala
              </h1>
              <p className="mb-4">
                At Internshipwala, we believe in accessible, innovative learning
                experiences that adapt to your schedule and learning style. Join
                us in embracing the future of education and unlock your
                potential with our immersive online courses.
              </p>
              <p className="mb-4">
                Welcome to Internshipwala, your gateway to boundless learning
                opportunities. We're dedicated to democratizing education,
                offering a diverse range of courses taught by industry experts.
                Our mission is to empower learners worldwide, fostering a
                community-driven platform where knowledge knows no limits.
              </p>

              {/* List of Features */}
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Expert Instructors
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Live Interactive
                    Sessions
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Comprehensive
                    Course Catalog
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Community
                    Engagement
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Personalized
                    Learning Paths
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Certification and
                    Recognition
                  </p>
                </div>
              </div>

              {/* Read More Button */}
              <a className="btn text-light py-3 px-5 mt-2" href="/aboutus">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

     

      <div className="container-xxl py-5 category">
        <div className="container">
          {/* Title Section */}
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Categories
            </h6>
            <h1 className="mb-5" style={{ color: "#fb873f" }}>
              Popular Topics to Explore
            </h1>
          </div>

          {/* Categories Grid */}
          <div className="row g-2 m-2">
            {categories.map((category, index) => (
              <div className="col-lg-3 col-md-6 text-center" key={index}>
                <div
                  className="content shadow p-3 mb-2 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <img
                    src={category.imgSrc}
                    className="img-fluid"
                    alt={category.name}
                  />
                  <h5 className="my-2">
                    <a href="#" className="text-center">
                      {category.name}
                    </a>
                  </h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Popular Courses
            </h6>
            <h1 className="mb-5" style={{color: "#fb873f"}}>
              Explore new and trending free online courses
            </h1>
          </div>
          <div class="row g-4 py-2">
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item shadow">
              <div className="position-relative overflow-hidden text-light image">
                <img className="img-fluid" src="img/course-1.jpg" alt="" />
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "16px",
                    fontSize: "12px",
                    borderRadius: "3px",
                    backgroundColor: "#fb873f",
                  }}
                  className="px-2 py-1 fw-bold text-uppercase"
                >
                  FREE
                </div>
              </div>
              <div className="p-2 pb-0">
                <h5 className="mb-1">
                  <a href="#" className="text-dark">
                    HTML Course for Beginners
                  </a>
                </h5>
              </div>
              <div className="d-flex">
                <small className="flex-fill text-center py-1 px-2">
                  <i className="fa fa-star text-warning me-2"></i>4.55
                </small>
                <small className="flex-fill text-center py-1 px-2">
                  <i className="fa fa-user-graduate me-2"></i>5.8L+ Learners
                </small>
                <small className="flex-fill text-center py-1 px-2">
                  <i className="fa fa-user me-2"></i>Beginner
                </small>
              </div>
              <div className="d-flex">
                <small className="flex-fill text-left p-2 px-2">
                  <i className="fa fa-clock me-2"></i>2.0 Hrs
                </small>
                <small className="py-1 px-2 fw-bold fs-6 text-center">₹ 0</small>
                <small className="text-primary py-1 px-2 fw-bold fs-6" style={{ float: "right" }}>
                  <a href="#">Enroll Now</a>
                  <i className="fa fa-chevron-right me-2 fs-10"></i>
                </small>
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </div>
      <div class="container text-center">
        <a class="btn text-light py-3 px-5 mt-2 mb-5" href="courses.html">
          All Courses
        </a>
      </div>


      <section className="pb-5" style={{ background: "url('img/banner-3.jpg')" }}>
  <div className="container-xxl mt-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
          <div className="position-relative h-100">
            <img
              className="img-fluid position-absolute w-100 h-100 bg-light"
              src="img/banner-2.png"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>

        <div className="col-lg-6 p-5 wow fadeInUp" data-wow-delay="0.3s">
          <h1 className="mb-4" style={{ color: "#fb873f"}}>
            Apply for an Internship
          </h1>
          <p className="mb-4 text-white" style={{ fontWeight: "bold" }}>
            Ready to gain hands-on experience in a fast-paced environment? Internships provide an invaluable opportunity to build your professional network, enhance your skills, and make a real impact. At [Your Company Name], we offer internships across various fields, ensuring you get the chance to work on exciting projects that will shape your future career.
          </p>
         
          <p className="mb-4 text-white" style={{ fontWeight: "bold" }}>
            During your internship, you'll get to:
          </p>
          <ul className="mb-4 text-white" style={{ fontWeight: "bold" }}>
            <li>Collaborate with a team of professionals on real projects</li>
            <li>Build valuable skills that will give you a competitive edge</li>
            <li>Gain exposure to industry-standard tools and practices</li>
            <li>Receive guidance and feedback from experienced mentors</li>
            <li>Expand your network and connect with professionals in your field</li>
          </ul>
         
        </div>
      </div>
    </div>
  </div>
</section>



<div className="container-xxl py-5 category">
  <div className="container">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
      <h1 className="mb-5"  style={{ color: "#fb873f"}}>Frequently Asked Questions</h1>
    </div>
    <div className="row g-2">
      <div className="col-12">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What is NIRC?
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC is a leading organization based in Bangalore that provides internship opportunities across all of India in fields like MERN Stack Development, Django Development, and Digital Marketing. We aim to empower students by offering hands-on experience and exposure to industry practices.
              </div>
            </div>
          </div>
          
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Why should I choose NIRC for an internship?
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC offers excellent internship opportunities in high-demand fields such as MERN Stack Development, Django Development, and Digital Marketing. With expert mentors and a focus on real-world projects, NIRC prepares interns for successful careers in tech and marketing.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How many internships can I apply for at NIRC?
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                At NIRC, you can apply for internships in multiple domains such as MERN Stack Development, Django Development, and Digital Marketing. We encourage you to explore and apply for internships based on your interests and career goals.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How can I enroll for an internship at NIRC?
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                To enroll for an internship at NIRC, visit our website, create an account, and apply for the internship of your choice. You can apply for internships in MERN Stack Development, Django Development, and Digital Marketing.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                What are the benefits of doing an internship at NIRC?
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Interning at NIRC allows you to gain valuable hands-on experience in high-demand areas like MERN Stack Development, Django Development, and Digital Marketing. You will have the opportunity to work on live projects, learn from industry experts, and boost your professional growth. Additionally, NIRC provides a certificate upon completion of your internship, enhancing your career prospects.
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>




    </div>
  );
}

export default Home;
