import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import AboutUs from './Components/AboutUs';
import Contact from './Components/Contact';
import Course from './Components/Course';
import Internship from './Components/pages/internships/internship';
import MernInternship from './Components/pages/internships/Web';
import DjangoInternship from './Components/pages/internships/Python';
import DigitalMarketingInternship from './Components/pages/internships/Digital';

function App() {
  return (
  <>
  <BrowserRouter>
  <Routes>
    <Route 
    path='/' 
    element={<><Header/><Home/><Footer/></>}/>
    <Route 
    path='/aboutus' 
    element={<><Header/><AboutUs/><Footer/></>}/>
    <Route 
    path='/contact' 
    element={<><Header/><Contact/><Footer/></>}/>
     <Route 
    path='/internship' 
    element={<><Header/><Internship/><Footer/></>}/>
    <Route 
    path='/web' 
    element={<><Header/><MernInternship/><Footer/></>}/>
        <Route 
    path='/python' 
    element={<><Header/><DjangoInternship/><Footer/></>}/>
           <Route 
    path='/digital' 
    element={<><Header/><DigitalMarketingInternship/><Footer/></>}/>
    <Route 
    path='/course' 
    element={<><Header/><Course/><Footer/></>}/>
  </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
