import { React, useState } from "react";
import "./web.css";
import { motion } from "framer-motion";
import img from './programming-and-engineering-development-tiny-girl-programmer-or-developer-create-code-programming-language-php-html-css-js-modern-flat-cartoon-style-illustration-on-white-background-vector-removebg-pr.png';
import { FaCheckCircle, FaHandsHelping, FaCogs, FaStar } from "react-icons/fa";

const learningTopics = [
    { title: "Full-Stack Web Development", desc: "Database design, CRUD operations, indexing, aggregation pipelines." },
    { title: "Frontend Mastery", desc: "RESTful APIs, middleware, authentication, security best practices." },
    { title: "Backend Development", desc: "Dynamic UIs, state management, hooks, component-based architecture." },
    { title: "Project Deployment", desc: "Event-driven architecture, streams, real-time applications." },
];

const MernInternship = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    return (
        <>
            <div className="internships-container">
                {/* Banner Section */}
                <div
                    style={{
                        background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className="container-fluid bg-primary py-5 mb-5 page-header"
                >
                    <div className="container py-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 text-center">
                                <h1 className="display-3 text-white text-xl animated slideInDown">
                                    MERN Stack Development Internship
                                </h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item">
                                            <a className="text-white" href="/">
                                                Home
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">
                                            MERN Stack Development Internship
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mern-internship">
                {/* Topic Section */}
                <div className="internship-info">
                    <div className="text-section">
                        <h2>MERN Stack Development Internship</h2>
                        <p>
                            Join our exclusive MERN Stack Development Internship Program where you’ll get hands-on experience with MongoDB, Express.js, React.js, and Node.js. This program is designed to provide you with real-world exposure to the MERN stack, allowing you to build full-stack applications while working on challenging projects. Gain invaluable skills and mentoring from industry experts.
                        </p>
                        <p>
                            By the end of the internship, you will have mastered the skills necessary to build dynamic and scalable full-stack applications and be well-prepared to take on a role in any top-tier development team.
                        </p>
                        <p>
                            This internship is a unique opportunity to transform your theoretical knowledge into practical skills, giving you the confidence and expertise to excel in the competitive world of web development.
                        </p>
                        <button className="register-btn">Register Now</button>
                    </div>
                    <div className="image-section">
                        <img src="https://miro.medium.com/v2/resize:fit:1400/1*Hm-G7dLwMZtLOPWbL6nkww.jpeg" alt="MERN Stack" className="internship-image" />
                    </div>
                </div>

                {/* What You Will Learn Section */}
                <motion.div
                    className="what-you-will-learn"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <h3 className="learn-heading">What You Will Learn</h3>
                    <div className="learn-container">
                        {learningTopics.map((item, index) => (
                            <motion.div
                                key={index}
                                className={`learn-item ${selectedCard === index ? "selected" : ""}`}
                                whileHover={{ scale: 1.08 }}
                                initial={{ opacity: 0, y: 30 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                onClick={() => setSelectedCard(index)}
                            >
                                <h4>{item.title}</h4>
                                <p>{item.desc}</p>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
                <br />

                {/* Why Choose Us Section */}
                <section className="why-choose-us1">
                    <div className="container1">
                        <div className="image1">
                            <img src={img} alt="Why Choose Us" />
                        </div>
                        <div className="content1">
                            <h2>Why Choose Our MERN Stack Internship?</h2>
                            <ul>
                                <li>
                                    <FaCheckCircle className="icon" />
                                    <div className="text">
                                        <strong>Hands-on Experience</strong>
                                        <p>Gain practical experience in developing web applications using MongoDB, Express, React, and Node.js. Our internship focuses on real-world projects that enhance your skills.</p>
                                    </div>
                                </li>
                                <li>
                                    <FaHandsHelping className="icon" />
                                    <div className="text">
                                        <strong>Mentorship & Guidance</strong>
                                        <p>Our experienced mentors provide personalized guidance, helping you grow as a developer and prepare for a successful career in full-stack development.</p>
                                    </div>
                                </li>
                                <li>
                                    <FaStar className="icon" />
                                    <div className="text">
                                        <strong>Real-world Projects</strong>
                                        <p>Work on live projects that simulate real-world business problems, giving you a portfolio of applications that you can showcase to future employers.</p>
                                    </div>
                                </li>
                                <li>
                                    <FaCheckCircle className="icon" />
                                    <div className="text">
                                        <strong>Collaborative Environment</strong>
                                        <p>Our internship promotes teamwork and collaboration, allowing you to work in a professional environment alongside experienced developers and designers.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Testimonials Section */}
                <div className="testimonials">
                    <h3>What Our Interns Say:</h3>
                    <div className="testimonial-cards">
                        <div className="testimonial-card">
                            <p>"This MERN Stack internship provided me with the hands-on experience I needed to truly understand full-stack development. The projects were challenging and rewarding, and the team was always there to guide me!"</p>
                            <span>- Aarav Sharma, Intern</span>
                        </div>
                        <div className="testimonial-card">
                            <p>"The internship was an incredible learning experience. I gained practical knowledge of MongoDB, Express, React, and Node.js, and the team was very supportive throughout. "</p>
                            <span>- Priya Patel, Intern</span>
                        </div>
                        <div className="testimonial-card">
                            <p>"A fantastic internship that helped me grow both technically and professionally. I got to work on live projects and collaborate with talented developers, which was a great boost to my career!"</p>
                            <span>- Rohit Kumar, Intern</span>
                        </div>
                        <div className="testimonial-card">
                            <p>"I highly recommend this MERN Stack internship! The real-world projects, mentorship, and support from the team made it a great learning opportunity. I feel more confident about my career in web development now!"</p>
                            <span>- Sneha Reddy, Intern</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MernInternship;
