import React from 'react';
import './Internships.css'; // Import CSS file
import { motion } from "framer-motion";

const Internships = () => {
  return (
    <>
      <div className="internships-container">
        {/* Banner Section */}
        <div
          style={{
            background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="container-fluid bg-primary py-5 mb-5 page-header"
        >
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <h1 className="display-3 text-white text-xl animated slideInDown">
                  Internships
                </h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a className="text-white" href="/">
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item text-white active" aria-current="page">
                      Internships
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* Cards Section */}
        <div className="cards-container">
          <div className="card">
            <div className="card-image" style={{ backgroundImage: `url('https://demo2.themelexus.com/editech/wp-content/uploads/2021/03/services-3.jpg')` }}>
              <div className="card-gradient"></div>
              <div className="card-title">
                <i className="card-icon fa fa-code"></i> {/* Icon for Full Stack Web Development */}
                <h3 style={{color:'white'}}>MERN Stack Development</h3>
              </div>
            </div>
            <div className="card-hover-content">
              <div className="card-icon-text">
                <p>Gain hands-on experience in building dynamic web applications using MongoDB, Express.js, React.js, and Node.js, working on both front-end and back-end development.</p>
              </div>
              <a href='/web'>
              <button className="card-button">Learn More</button></a>
            </div>
          </div>

          <div className="card">
            <div className="card-image" style={{ backgroundImage: `url('https://demo2.themelexus.com/editech/wp-content/uploads/2021/03/services-5.jpg')` }}>
              <div className="card-gradient"></div>
              <div className="card-title">
                <i className="card-icon fa fa-cogs"></i> {/* Icon for Data Science with Machine Learning */}
                <h3 style={{color:'white'}}>Python Django Web Development</h3>
              </div>
            </div>
            <div className="card-hover-content">
              <div className="card-icon-text">
                <p>Work on developing backend solutions with Python and Django, focusing on database management, API creation, and server-side logic for web applications.</p>
              </div>
             <a href='/python'> <button className="card-button">Learn More</button></a>
            </div>
          </div>

          <div className="card">
            <div className="card-image" style={{ backgroundImage: `url('https://demo2.themelexus.com/editech/wp-content/uploads/2021/03/services-4.jpg')` }}>
              <div className="card-gradient"></div>
              <div className="card-title">
                <i className="card-icon fa fa-microchip"></i> {/* Icon for Embedded System */}
                <h3 style={{color:'white'}}>Digital Marketing Internship</h3>
              </div>
            </div>
            <div className="card-hover-content">
              <div className="card-icon-text">
                <p>Assist in creating and executing digital marketing campaigns, including SEO, social media management, and content optimization, while analyzing campaign performance</p>
              </div>
              <a href='/digital'><button className="card-button">Learn More</button></a>
            </div>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="container my-5 whyy-choose-us">
          <h2 className="section-heading text-center mb-4">Why Choose Us?</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="why-card">
                <div className="why-card-title">
                  <i className="fa fa-lightbulb"></i>
                  <h3>Innovative Learning</h3>
                </div>
                <p className="why-card-text">Our internships provide hands-on learning with cutting-edge technologies, helping you stay ahead of industry trends.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="why-card">
                <div className="why-card-title">
                  <i className="fa fa-users"></i>
                  <h3>Collaborative Environment</h3>
                </div>
                <p className="why-card-text">Work with industry experts and collaborate with like-minded individuals to build real-world projects.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="why-card">
                <div className="why-card-title">
                  <i className="fa fa-briefcase"></i>
                  <h3>Job Readiness</h3>
                </div>
                <p className="why-card-text">Gain skills that are highly valued in the job market, making you job-ready for the tech industry.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Internships;
