



import { React, useState } from "react";
import "./digital.css";
import { motion } from "framer-motion";
import img from './programming-and-engineering-development-tiny-girl-programmer-or-developer-create-code-programming-language-php-html-css-js-modern-flat-cartoon-style-illustration-on-white-background-vector-removebg-pr.png';
 import { FaChartLine, FaBullhorn, FaUsers, FaCheckCircle } from "react-icons/fa";

const learningTopics = [
        { title: "SEO & Search Engine Marketing", desc: "Learn how to optimize websites for search engines and run paid ad campaigns." },
         { title: "Social Media Marketing", desc: "Create engaging content and run targeted ad campaigns on social media platforms." },
        { title: "Content Marketing & Blogging", desc: "Master content strategies, blog writing, and brand storytelling." },
         { title: "Email & Affiliate Marketing", desc: "Use email automation and affiliate marketing techniques to drive sales." },
     ];
    

const DigitalMarketingInternship = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    return (
        <>
            <div className="internships-container">
                {/* Banner Section */}
                <div
                    style={{
                        background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className="container-fluid bg-primary py-5 mb-5 page-header"
                >
                    <div className="container py-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 text-center">
                                <h1 className="display-3 text-white text-xl animated slideInDown">
                                    Digital Marketing Internship
                                </h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item">
                                            <a className="text-white" href="/">
                                                Home
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">
                                            Digital Marketing Internship
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="digital-marketing-internship">
                <div className="internship-info">
                    <div className="text-section">
                        <h2>Digital Marketing Internship</h2>
                         <p>
                           Kickstart your career in digital marketing with our hands-on internship program. Learn SEO, social media marketing, PPC advertising, and content creation from industry experts.
                        </p>
                         <p>
                            Gain real-world experience by working on live marketing campaigns, analytics reports, and lead generation strategies. This internship will equip you with the skills needed to become a successful digital marketer.
                        </p>
                        <p>
                            By the end of this internship, you will have a portfolio showcasing your expertise in digital marketing, helping you secure high-paying roles in the industry.
                        </p>
                         <button className="register-btn">Register Now</button>
                     </div>
                    <div className="image-section">
                         <img src="https://mywritingservices.in/wp-content/uploads/Digital-marketing-internship-e1616331479327.jpg" 
                          alt="Digital Marketing" className="internship-image" />                     </div>
                 </div>

                {/* What You Will Learn Section */}
                <motion.div
                     className="what-you-will-learn"
                    initial={{ opacity: 0, y: 50 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ duration: 1 }}
                 >
                    <h3 className="learn-heading">What You Will Learn</h3>
                     <div className="learn-container">
                         {learningTopics.map((item, index) => (
                            <motion.div
                            key={index}
                            className={`learn-item ${selectedCard === index ? "selected" : ""}`}
                             whileHover={{ scale: 1.08 }}
                              initial={{ opacity: 0, y: 30 }}
                               animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                onClick={() => setSelectedCard(index)}
                             >
                                 <h4>{item.title}</h4>
                                 <p>{item.desc}</p>
                             </motion.div>
                         ))}
                     </div>
              </motion.div>
                <br />

                {/* Why Choose Us Section */}
                <section className="why-choose-us">
                     <div className="container1">
                         <div className="image1">
                             <img src={img} alt="Why Choose Us" />
                         </div>
                         <div className="content1">
                             <h2>Why Choose Our Digital Marketing Internship?</h2>
                             <ul>
                                 <li>
                                     <FaCheckCircle className="icon" />
                                     <div className="text">
                                         <strong>Hands-On Training</strong>
                                         <p>Work on live campaigns, SEO optimization, and PPC ads with expert guidance.</p>
                                     </div>
                                 </li>
                                 <li>
                                     <FaBullhorn className="icon" />                                     <div className="text">
                                        <strong>Social Media & Brand Growth</strong>
                                         <p>Learn how to scale businesses using social media engagement and influencer marketing.</p>
                                     </div>
                                 </li>
                                 <li>
                                     <FaChartLine className="icon" />
                                     <div className="text">
                                         <strong>Analytics & Performance Tracking</strong>
                                         <p>Gain expertise in tools like Google Analytics and Facebook Ads Manager.</p>
                                     </div>
                                 </li>
                                 <li>
                                     <FaUsers className="icon" />
                                     <div className="text">
                                         <strong>Career Opportunities</strong>
                                         <p>Get industry-ready skills to land high-paying roles in digital marketing agencies.</p>
                                     </div>
                                 </li>
                             </ul>
                         </div>
                     </div>
                </section>

                {/* Testimonials Section */}
                <div className="testimonials">
                    <h3>What Our Interns Say:</h3>
                    <div className="testimonial-cards">
                    <div className="testimonial-card">
                             <p>"This internship helped me master SEO and Facebook Ads. I even got a freelance gig right after completing the program!"</p>
                             <span>- Anjali Kapoor, Intern</span>
                         </div>
                         <div className="testimonial-card">
                             <p>"Learning real-world marketing strategies was the best part. The hands-on training boosted my confidence!"</p>
                            <span>- Rajesh Kumar, Intern</span>
                         </div>
                         <div className="testimonial-card">
                             <p>"I worked on actual social media campaigns and analytics. This experience helped me land a job at a marketing agency!"</p>
                             <span>- Rohan Mehta, Intern</span>
                         </div>
                         <div className="testimonial-card">
                             <p>"The program was well-structured, and the mentorship was excellent. I learned everything from content writing to ad strategies!"</p>
                             <span>- Sneha Agarwal, Intern</span>
                       </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DigitalMarketingInternship;



