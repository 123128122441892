import React from "react";
import img from './sunilf.jpg'
import img1 from './WhatsApp Image 2025-02-14 at 5.58.23 PM.jpeg'
import img3 from './WhatsApp Image 2025-02-14 at 5.58.23 PM.jpeg'

function AboutUs() {
  return (
    <div>
      {/* Header Start */}
      <div
        style={{
          background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="container-fluid bg-primary py-5 mb-5 page-header"
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            {/* Left Side - Image */}
            <div className="col-lg-6">
              <img
                src={img3}
                alt="About Us"
                className="img-fluid "
                style={{height:'400px', borderRadius:'20px'}}
              />
            </div>
            {/* Right Side - Text */}
            <div className="col-lg-6">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Internshipwala: Empowering the Next Generation of Innovators
              </h1>
              <p className="mb-4" style={{textAlign:'justify'}}>
                At NIRC, we don’t just teach technology—we shape future-ready
                professionals. As the best center for job-oriented training, we
                offer hands-on development in the latest technologies, including
                Python, Django, ReactJS, and Node.js.
              </p>
              <p className="mb-4" style={{textAlign:'justify'}}>
                Our approach is simple yet powerful: real-world projects, expert
                mentorship, and a curriculum designed to meet industry demands.
                Whether you're a student or a professional looking to upskill,
                NIRC equips you with the practical experience and technical
                expertise to excel in today's competitive job market. Join us
                and transform your learning into a career!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Vision Section */}
      <div className="container py-5">
      <div className="row g-5 align-items-center">
          {/* Left - Image */}
          
          
          {/* Right - Text */}
          <div className="col-lg-6">
            <h2 className="mb-3" style={{ color: "#fb873f" }} >Our Vision</h2>
            <p style={{textAlign:'justify'}}> 
            At NIRC, our vision is to bridge the gap between education and industry by providing hands-on, job-oriented training that empowers students to excel in the ever-evolving tech landscape. We strive to be the leading technology and research center where innovation meets practical learning, ensuring every student gains the skills, confidence, and experience needed to succeed in the modern workforce.

            </p>
          </div>
         
          <div className="col-lg-6">
            <img
              src="img/about.jpg"
              alt="Our Vision"
              className="img-fluid"
              style={{borderRadius:'20px'}}
            />
        </div>
        </div>
      </div>

      {/* Mission Section */}
      <div className="container py-5">
        <div className="row g-5 align-items-center flex-row-reverse">
          {/* Right - Image */}
          
          {/* Left - Text */}
          <div className="col-lg-6">
            <h2 className="mb-3" style={{ color: "#fb873f" }}>Our Mission</h2>
            <p style={{textAlign:'justify'}}>
            Our mission is to produce 100% successful students by equipping them with hands-on training in the latest technologies. We are committed to bridging the gap between academic learning and industry requirements by providing real-world projects, expert mentorship, and job-oriented skills. At NIRC, we ensure that every student gains the confidence, competence, and practical experience needed to excel in their careers and become industry-ready professionals.

            </p>
          </div>
          <div className="col-lg-6">
            <img
              src='https://img.jagranjosh.com/imported/images/E/Articles/how-to-find-internships.webp'
              alt="Our Mission"
              className="img-fluid "
              style={{borderRadius:'20px'}}
            />
          </div>
        </div>
      </div>

      {/* Director’s Message */}
     <div className="container-xxl py-5 category">
  <div className="container">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
      <h1 className="mb-5">Frequently Asked Questions</h1>
    </div>
    <div className="row g-2">
      <div className="col-12">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What is NIRC?
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC
              </div>
            </div>
          </div>
          
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Why should I choose NIRC?
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How many NIRC courses can I enroll in at the same time?
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How can I enroll in NIRC courses?
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                What are the most popular NIRC courses?
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                NIRC

                <p>Some of the most popular NIRC courses are:</p>
                <ul>
                  <li>NIRC Data Science Courses</li>
                  <li>NIRC Artificial Intelligence Courses</li>
                  <li>NIRC Software Courses</li>
                  <li>NIRC Cloud Computing Courses</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
}

export default AboutUs;
