import { React, useState } from "react";
import "./python.css";
import { motion } from "framer-motion";
import img from './programming-and-engineering-development-tiny-girl-programmer-or-developer-create-code-programming-language-php-html-css-js-modern-flat-cartoon-style-illustration-on-white-background-vector-removebg-pr.png';
import { FaCheckCircle, FaHandsHelping, FaCogs, FaStar } from "react-icons/fa";

const learningTopics = [
    { title: "Django Fundamentals", desc: "Learn Django framework, models, views, templates, and ORM." },
    { title: "Backend Development", desc: "API development, authentication, middleware, and security best practices." },
    { title: "Frontend Integration", desc: "Integrate Django with modern frontend frameworks like React." },
    { title: "Project Deployment", desc: "Deploy Django projects using cloud platforms and CI/CD pipelines." },
];

const DjangoInternship = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    return (
        <>
            <div className="internships-container">
                {/* Banner Section */}
                <div
                    style={{
                        background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className="container-fluid bg-primary py-5 mb-5 page-header"
                >
                    <div className="container py-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 text-center">
                                <h1 className="display-3 text-white text-xl animated slideInDown">
                                    Python Django Web Development Internship
                                </h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item">
                                            <a className="text-white" href="/">
                                                Home
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">
                                            Python Django Web Development Internship
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="django-internship">
                {/* Topic Section */}
                <div className="internship-info">
                    <div className="text-section">
                        <h2>Python Django Web Development Internship</h2>
                        <p>
                            Join our Python Django Web Development Internship to gain practical experience in backend web development using Django. This internship provides in-depth exposure to Django's core features, allowing you to build robust web applications from scratch.
                        </p>
                        <p>
                            Throughout the program, you will work on real-world projects, integrate Django with frontend frameworks, and deploy scalable web applications. Our expert mentors will guide you through each step, helping you become a skilled backend developer.
                        </p>
                        <p>
                            By the end of this internship, you will have the skills needed to work on professional Django projects and kickstart your career as a backend developer.
                        </p>
                        <button className="register-btn">Register Now</button>
                    </div>
                    <div className="image-section">
                        <img src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/319405238/original/479f0c2b4f8aaa21b4e3591285a30004393e94e7/do-python-as-full-stack-frontend-backend-create-api-automation-scrapping-etc.jpg" alt="Django Development" className="internship-image" />
                    </div>
                </div>

                {/* What You Will Learn Section */}
                <motion.div
                    className="what-you-will-learn"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <h3 className="learn-heading">What You Will Learn</h3>
                    <div className="learn-container">
                        {learningTopics.map((item, index) => (
                            <motion.div
                                key={index}
                                className={`learn-item ${selectedCard === index ? "selected" : ""}`}
                                whileHover={{ scale: 1.08 }}
                                initial={{ opacity: 0, y: 30 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                onClick={() => setSelectedCard(index)}
                            >
                                <h4>{item.title}</h4>
                                <p>{item.desc}</p>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
                <br />

                {/* Why Choose Us Section */}
                <section className="why-choose-us1">
                    <div className="container1">
                        <div className="image1">
                            <img src={img} alt="Why Choose Us" />
                        </div>
                        <div className="content1">
                            <h2>Why Choose Our Python Django Internship?</h2>
                            <ul>
                                <li>
                                    <FaCheckCircle className="icon" />
                                    <div className="text">
                                        <strong>Practical Experience</strong>
                                        <p>Build real-world web applications using Django and learn how to develop robust backend systems.</p>
                                    </div>
                                </li>
                                <li>
                                    <FaHandsHelping className="icon" />
                                    <div className="text">
                                        <strong>Expert Mentorship</strong>
                                        <p>Receive guidance from industry professionals to enhance your coding skills and best practices.</p>
                                    </div>
                                </li>
                                <li>
                                    <FaStar className="icon" />
                                    <div className="text">
                                        <strong>Live Projects</strong>
                                        <p>Work on industry-level projects and build a portfolio that stands out in the job market.</p>
                                    </div>
                                </li>
                                <li>
                                    <FaCheckCircle className="icon" />
                                    <div className="text">
                                        <strong>Career Growth</strong>
                                        <p>Our internship prepares you for a professional career by providing hands-on training and networking opportunities.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Testimonials Section */}
                <div className="testimonials">
                    <h3>What Our Interns Say:</h3>
                    <div className="testimonial-cards">
                        <div className="testimonial-card">
                            <p>"This internship was an excellent way to dive into Django development. I learned backend skills and worked on live projects that prepared me for job opportunities."</p>
                            <span>- Ankit Verma, Intern</span>
                        </div>
                        <div className="testimonial-card">
                            <p>"I gained hands-on experience with Django and API development. The mentorship was fantastic, and I now feel confident in my backend development skills."</p>
                            <span>- Sneha Sharma, Intern</span>
                        </div>
                        <div className="testimonial-card">
                            <p>"The internship helped me build full-stack applications and deploy them to the cloud. It was a great learning experience with real-world exposure!"</p>
                            <span>- Rahul Mehta, Intern</span>
                        </div>
                        <div className="testimonial-card">
                            <p>"A well-structured program with practical assignments and mentorship. It gave me the confidence to apply for backend developer roles."</p>
                            <span>- Priyanka Das, Intern</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DjangoInternship;
